import React, { useState, useEffect } from 'react';

// Countdown component
const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div>
      {Object.keys(timeLeft).length === 0 ? (
        <p>Thanksgiving is here! 🎉</p>
      ) : (
        <p>
          Hurry! Only {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s left!
        </p>
      )}
    </div>
  );
};

const Thanksgiving = () => {
    // Target date for Thanksgiving Day (November 28th, 2024)
    const thanksgivingDate = new Date("2024-11-28T00:00:00");
  
    return (
      <div style={styles.page}>
        <header style={styles.header}>
          <h1 style={styles.title}>Happy Thanksgiving from Shoreham Drugs!</h1>
        </header>
        <section style={styles.content}>
          {/* Left Side: Text and Offer */}
          <div style={styles.leftContent}>
            <p style={styles.greeting}>
              We are grateful for your support and wish you and your loved ones a wonderful Thanksgiving!
            </p>
            <h2 style={styles.offer}>🎉 50% OFF on All Greeting Cards 🎉</h2>
            <p style={styles.offerDetails}>
              Take advantage of our special offer! Get 50% off on all greeting cards in-store and online. Hurry,
              this offer is only valid until Thanksgiving Day!
            </p>
            <Countdown targetDate={thanksgivingDate} />
          </div>
  
          {/* Right Side: Images */}
          <div style={styles.rightContent}>
            <img 
              src="img_halloween_cards.png" 
              alt="Thanksgiving Cards 1" 
              style={styles.image} 
            />
            <img 
              src="halloween_cards_2.png" 
              alt="Thanksgiving Cards 2" 
              style={styles.image} 
            />
          </div>
        </section>
      </div>
    );
  };


// Inline styles
const styles = {
  page: {
    // fontFamily: 'Arial, sans-serif',
    fontWeight:'bold',
    color: '#333',
    textAlign: 'center',
    margin: '0',
    padding: '0',
    backgroundColor: '#f9f9f9',
  },
  header: {
    display:'flex',
    backgroundColor: '#E21F46',
    fontWeight:'bold',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '200px',
    color: 'white',
  },
  title: {
    margin: '0',
    fontSize: '36px',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'bold'
  },
  content: {
    padding: '30px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '20px auto',
  },
  leftContent: {
    flex: 1,
    marginRight: '20px', // Space between text and images
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  greeting: {
    fontSize: '24px',
    color: '#555',
  },
  offer: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#ff6600',
    marginTop: '20px',
  },
  offerDetails: {
    fontSize: '18px',
    color: '#333',
    marginTop: '10px',
  },
  image: {
    maxHeight: '40%',
    width: '100%',
    marginBottom: '20px',
    objectFit: 'cover', // To maintain image aspect ratio without distortion
  },
  ctaButton: {
    backgroundColor: '#ff6600',
    color: 'white',
    fontSize: '18px',
    border: 'none',
    padding: '15px 30px',
    cursor: 'pointer',
    borderRadius: '5px',
    marginTop: '20px',
    textTransform: 'uppercase',
  },
  footer: {
    backgroundColor: '#E21F46',
    color: 'white',
    padding: '10px 0',
    marginTop: '40px',
  },
  footerText: {
    margin: '0',
    fontSize: '16px',
  },
};

export default Thanksgiving;
