import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Halloween = () => {
  return (
    <Container className="my-5">
      <div style={{ backgroundColor: '#E21F46', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ color: 'white', fontWeight: 'bold' }} className="text-center mb-4">🎃 Spooktacular Savings on Halloween Cards! 🎃</h1>
      </div>
      <Row className="mt-5">
        <Col md={6} style={{ textAlign: 'left', padding: '20px', backgroundColor: '#f8f8f8' }}>
          <p>
            Celebrate the spooky season with us at Shoreham Drugs! 
            From now until October 31st, enjoy a thrilling <strong>50% off</strong> on all Halloween cards.
          </p>
          <h2 style={{ color: '#8b0000' }}>🕷️ Why Choose Our Halloween Cards?</h2>
          <ul style={{ listStyleType: 'none', padding: '0' }}>
            <li>✨ Unique Designs: Discover a variety of creative and fun cards!</li>
            <li>🎉 Perfect for Everyone: Something for friends, family, or colleagues.</li>
            <li>💌 Great Quality: Crafted with care for a memorable message.</li>
          </ul>
          <p>
            🕸️ <strong>Don’t Miss Out!</strong> Visit us in-store or online to take advantage of this fang-tastic deal. 
            Stock up on your Halloween cards before they disappear! 
            Offer valid until October 31st, 2024.
          </p>
          <h2>👻 Come Visit Our Pharmacy ASAP!</h2>
        </Col>
        <Col md={6} className="d-flex flex-column align-items-left">
          <img src="img_halloween_cards.png" alt="Halloween Cards" style={{ maxHeight:'40%', width: '100%', marginBottom: '20px' }} />
          <img src="halloween_cards_2.png" alt="Halloween Cards 2" style={{ maxHeight:'40%', width: '100%' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Halloween;
