import React, { useState } from "react";
import { Form, Button, Col, Row, Container, Card, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";  // Make sure to import Bootstrap styles
import CryptoJS from 'crypto-js';

const Transfer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    pharmacyName: "",
    pharmacyNumber: "",
    transferOption: "all", // default to "Transfer all my medication"
    rxNumbers: "", // Holds the Rx numbers for the selected option
    termsChecked: false,
    receiveMessagesChecked: false,
  });
  const SECRET_KEY = 'G7#tA9s&jD2@vX!rQ4$yHn8zWmP3*eZf';
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Form Submitted", formData);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error/success message
    setErrorMessage('');
    setSuccessMessage('');
    
    const currentDate = new Date().toISOString();
    const nonceValue = Math.random().toString(36).substring(2, 15);  // Unique value to prevent replay
    //const key  = CryptoJS.AES.encrypt(currentDate, SECRET_KEY).toString();
    //const nonce  = CryptoJS.AES.encrypt(nonceValue, SECRET_KEY).toString();

    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY); // Ensure this is the same key used on the server

    const encryptedKey = CryptoJS.AES.encrypt(currentDate, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    // Combine IV and ciphertext
    const combinedKey = iv.concat(encryptedKey.ciphertext);

    const keyCipher = CryptoJS.enc.Base64.stringify(combinedKey);
    debugger;
    const jsonRequest = JSON.stringify(formData);
    // Call the web service with fetch
    try {
      const response = await fetch('https://www.shorehamdrugs.com/web-api/pharmacy/api/submittransfer?key=' + encodeURIComponent(keyCipher) + '&nonce=' + encodeURIComponent(nonceValue), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonRequest
      });

      
      if (!response.ok) {
        debugger;
        throw new Error('Network response was not ok');
      }

      debugger;
      const data = await response.text();
      setSuccessMessage(data);
      //console.log(data);
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Failed to submit the transfer request. Please try again later.');
    }
  };

  return (
    <Container className="mt-4">
      <div style={{ backgroundColor: '#E21F46', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }} className="mb-2">
          Unhappy with Your Current Pharmacy? Let Us Make the Switch Easy!
        </h1>
        <h2 style={{ color: 'white', textAlign: 'center', justifyContent: 'left' }}>
          Just provide us with the following information, and join the best pharmacy in town – we’ll take care of the rest!
        </h2>
      </div>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit} className="centered-form">
            {/* Header */}
            <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>TRANSFER FORM</h2>

            {errorMessage && (
              <Alert style={{ display: 'flex', color: 'maroon', margin: '0px', position: 'relative' }} variant="danger">
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert style={{ display: 'flex', color: 'darkgreen', margin: '0px', position: 'relative' }} variant="success">
                {successMessage}
              </Alert>
            )}
            {/* Personal Information Section */}
            <Row className="mt-6">
              <Col sm={12} md={6}>
                <Form.Group controlId="formFirstName">
                  <Form.Label style={{ fontWeight: "bold" }}>First Name: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="formLastName">
                  <Form.Label style={{ fontWeight: "bold" }}>Last Name: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={12} md={6}>
                <Form.Group controlId="formDateOfBirth">
                  <Form.Label style={{ fontWeight: "bold" }}>Date of Birth: </Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label style={{ fontWeight: "bold" }}>Email: </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={12} md={6}>
                <Form.Group controlId="formPhone">
                  <Form.Label style={{ fontWeight: "bold" }}>Phone Number: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your phone number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label style={{ fontWeight: "bold" }}>Address: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Pharmacy Information Section */}
            <h4 className="text-left mt-4" >Previous Pharmacy Information</h4>
            <Row className="mt-4">
              <Col sm={12} md={6}>
                <Form.Group controlId="formPharmacyName">
                  <Form.Label style={{ fontWeight: "bold" }}>Pharmacy Name: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your pharmacy name"
                    name="pharmacyName"
                    value={formData.pharmacyName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="formPharmacyNumber">
                  <Form.Label style={{ fontWeight: "bold" }}>Pharmacy Phone Number: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your pharmacy number"
                    name="pharmacyNumber"
                    value={formData.pharmacyNumber}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Transfer Options Section */}
            <Form.Group className="mt-3">
              <Form.Label style={{ fontWeight: "bold" }}>Transfer Option: </Form.Label>
              <div className="d-flex justify-content-start">
                <Form.Check
                  type="radio"
                  label="Transfer all my medication"
                  name="transferOption"
                  value="all"
                  checked={formData.transferOption === "all"}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Transfer following medication(s)"
                  name="transferOption"
                  value="following"
                  checked={formData.transferOption === "following"}
                  onChange={handleChange}
                  style={{ marginLeft: "20px" }} 
                />
              </div>
            </Form.Group>

            {/* Conditionally Render Rx Numbers Text Area when "Transfer following medication(s)" is selected */}
            {formData.transferOption === "following" && (
              <Form.Group controlId="formRxNumbers" className="mt-2">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Enter Rx Numbers to Transfer:{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter Rx numbers separated by commas"
                  name="rxNumbers"
                  value={formData.rxNumbers}
                  onChange={handleChange}
                  required={formData.transferOption === "following"}
                />
              </Form.Group>
            )}

            {/* Terms and Checkbox Section */}
            <Form.Group className="mt-5">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    I agree to the{" "}
                    <a className="link1" href="./terms-and-conditions" target="_blank" rel="noopener noreferrer">
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a className='link1' href='./privacy-policy'>Privacy Policy</a>
                    {" "}
                  </span>
                }
                name="termsChecked"
                checked={formData.termsChecked}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Check
                type="checkbox"
                label="I agree to receive text messages from Shoreham Drugs Pharmacy regarding prescription updates, refill reminders, and promotions."
                name="receiveMessagesChecked"
                checked={formData.receiveMessagesChecked}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Submit Button (Full Width) */}
            <div className="text-center mt-4">
              <Button variant="primary" type="submit" className="w-100">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Transfer;
