// src/pages/FreeHomeDelivery.js
import React from 'react';
import { Container } from 'react-bootstrap';

const FreeHomeDelivery = () => {
  return (
    <Container className="my-5">
      <h1>Free Home Delivery</h1>
      <p>Details about free home delivery services.</p>
    </Container>
  );
};

export default FreeHomeDelivery;
