// src/pages/PrivacyPolicy.js
import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="my-5">
      <div style={{ backgroundColor: '#E21F46', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ color: 'white', fontWeight: 'bold' }} className="text-center mb-4">SHOREHAM DRUGS PRIVACY POLICY</h1>
      </div>
      <div className="center-container">
        <div className="terms-content">
          <p>Shoreham Drugs Pharmacy ("we," "us," or "our") operates the website shorehamdrugs.com (the "Site"). This Privacy Policy describes how we collect, use, and protect your information when you visit our Site.</p>

          <h2>1. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li><strong>Personal Information</strong>: This includes your name, email address, phone number, mailing address, and any other information you provide to us when you register, place a refill order, or contact us.</li>
            <li><strong>Non-Personal Information</strong>: We may collect information that does not identify you personally, such as browser type, operating system, and pages visited on our Site.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We may use the information we collect for various purposes, including:</p>
          <ul>
            <li>To process and fulfill your orders.</li>
            <li>To communicate with you about your account, orders, and customer service inquiries.</li>
            <li>To improve our Site and services.</li>
            <li>To send you promotional materials, if you have opted in to receive them.</li>
          </ul>

          <h2>3. Information Sharing</h2>
          <p>We do not sell or rent your personal information to third parties. We may share your information with trusted service providers who assist us in operating our Site or conducting our business, so long as those parties agree to keep this information confidential.</p>

          <h2>4. Cookies and Tracking Technologies</h2>
          <p>Our Site may use cookies and similar technologies to enhance your experience. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.</p>

          <h2>5. Security</h2>
          <p>We take the security of your personal information seriously and use reasonable precautions to protect it. However, no method of transmission over the Internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.</p>

          <h2>6. Your Rights</h2>
          <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
          <ul>
            <li>The right to access the personal information we hold about you.</li>
            <li>The right to request correction of any inaccurate or incomplete information.</li>
            <li>The right to request deletion of your personal information.</li>
          </ul>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page with a new effective date. We encourage you to review this Privacy Policy periodically for any updates.</p>

          <h2>8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p><strong>Shoreham Drugs Pharmacy</strong><br />
            99-1 Route 25A<br />
            Shoreham, NY 11786<br />
            Email: <a href="mailto:info@shorehamdrugs.com">info@shorehamdrugs.com</a><br />
            Phone: (631) 821-0707</p>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
