// src/pages/AboutUs.js
import React from 'react';
import { Container } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <Container className="my-5">
        <div style={{backgroundColor:'#E21F46', height:'300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1 style={{color:'white',fontWeight:'bold'}} className="text-center mb-4">SHOREHAM DRUGS TERMS AND CONDITIONS</h1>
        </div>
    <div className="center-container">
      <div className="terms-content">
      
      <p>We appreciate your visit to the Shoreham Drugs website (the "Site"). This Site is managed by Shoreham Drugs Pharmacy, located at 99-1 Route 25A, Shoreham, NY 11786, United States ("we," "us," or "our"). By using or accessing the Site, you agree to comply with these Terms and Conditions ("Terms").</p>

      <p>Thank you for visiting the Shoreham Drugs website (the "Site"). This Site is managed by Shoreham Drugs Pharmacy, located at 99-1 Route 25A, Shoreham, NY 11786, United States ("we," "us," or "our"). By using or accessing the Site, you agree to comply with these Terms and Conditions ("Terms").</p>

      <h2>1. Site Use</h2>
      <p>The Site is intended solely for your informational needs. You are prohibited from using the Site for any illegal or unauthorized activities. You agree to use the Site in compliance with all relevant laws and regulations.</p>

      <h2>2. Site Information</h2>
      <p>The content available on the Site, including information about medications and health issues, is meant for informational purposes only and is not a replacement for professional medical guidance. Always consult your physician or a qualified healthcare provider before making any health-related decisions.</p>

      <h2>3. Warranties Disclaimer</h2>
      <p>The Site is provided "as is," without any warranties, whether expressed or implied. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY DEFECTS WILL BE FIXED, OR THAT THE SITE OR THE SERVER HOSTING IT IS FREE OF VIRUSES OR OTHER HARMFUL ELEMENTS.</p>

      <h2>4. Liability Limitation</h2>
      <p>WE WILL NOT BE RESPONSIBLE FOR ANY DAMAGES RESULTING FROM OR RELATED TO YOUR USE OF THE SITE, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, AND SPECIAL DAMAGES.</p>

      <h2>5. Intellectual Property Rights</h2>
      <p>The Site and its contents, such as text, graphics, logos, images, and software, are protected under copyright and other intellectual property laws. You may not reproduce, alter, distribute, display, perform, create derivative works from, or commercially use any content on the Site without our explicit written consent.</p>

      <h2>6. Access Termination</h2>
      <p>We reserve the right to terminate your access to the Site at any time, for any reason, and without prior notice.</p>

      <h2>7. Governing Law</h2>
      <p>These Terms are governed by the laws of the State of New York, without regard to its conflict of law rules.</p>

      <h2>8. Complete Agreement</h2>
      <p>These Terms represent the complete agreement between you and us regarding your use of the Site.</p>

      <h2>9. Contact Information</h2>
      <p>For questions about these Terms, please reach out to us at <a href="mailto:info@shorehamdrugs.com">info@shorehamdrugs.com</a> or call us at (631) 821-0707.</p>

      <h2>10. Modifications to Terms</h2>
      <p>We reserve the right to update these Terms at any time. Your continued use of the Site after any changes are made constitutes your acceptance of those changes.</p>

      <h2>11. Severability Clause</h2>
      <p>If any part of these Terms is found to be invalid or unenforceable, that provision will be removed, and the remaining provisions will continue in full force.</p>
      </div>
    </div>
    </Container>
  );
};

export default TermsAndConditions;
