import React, { useState } from 'react';
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap';

const ERxInsCard = () => {
  const [isFrontTaken, setIsFrontTaken] = useState(false);
  const [isBackTaken, setIsBackTaken] = useState(false);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);

  const handleFrontPicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFrontImage(URL.createObjectURL(file));
      setIsFrontTaken(true);
    }
  };

  const handleBackPicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBackImage(URL.createObjectURL(file));
      setIsBackTaken(true);
    }
  };

  const handleFrontPicClick = () => {
    document.getElementById('frontPicInput').click();
  };

  const handleBackPicClick = () => {
    document.getElementById('backPicInput').click();
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg">
            <Card.Body>
              <h2 className="text-center mb-4">Take Pictures of Your Insurance Card</h2>

              {/* Front Image Section */}
              {!isFrontTaken && (
                <div className="text-center mb-4">
                  <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={handleFrontPicClick}
                  >
                    Take Front Picture
                  </Button>
                  <input
                    type="file"
                    id="frontPicInput"
                    accept="image/*"
                    capture="camera"
                    style={{ display: 'none' }}
                    onChange={handleFrontPicChange}
                  />
                </div>
              )}

              {isFrontTaken && frontImage && (
                <div className="text-center mb-4">
                  <h4>Front Picture</h4>
                  <Image 
                    src={frontImage} 
                    alt="Front Insurance Card" 
                    fluid 
                    rounded 
                    style={{ maxHeight: '300px' }} 
                  />
                  <div className="mt-3">
                    <Button 
                      variant="danger" 
                      onClick={() => setIsFrontTaken(false)}
                    >
                      Retry Front Picture
                    </Button>
                  </div>
                </div>
              )}

              {/* Back Image Section */}
              {isFrontTaken && !isBackTaken && (
                <div className="text-center mb-4">
                  <Button 
                    variant="secondary" 
                    size="lg" 
                    onClick={handleBackPicClick}
                  >
                    Take Back Picture
                  </Button>
                  <input
                    type="file"
                    id="backPicInput"
                    accept="image/*"
                    capture="camera"
                    style={{ display: 'none' }}
                    onChange={handleBackPicChange}
                  />
                </div>
              )}

              {isBackTaken && backImage && (
                <div className="text-center mb-4">
                  <h4>Back Picture</h4>
                  <Image 
                    src={backImage} 
                    alt="Back Insurance Card" 
                    fluid 
                    rounded 
                    style={{ maxHeight: '300px' }} 
                  />
                  <div className="mt-3">
                    <Button 
                      variant="danger" 
                      onClick={() => setIsBackTaken(false)}
                    >
                      Retry Back Picture
                    </Button>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ERxInsCard;
