// src/pages/CBDProducts.js
import React from 'react';
import { Container } from 'react-bootstrap';

const CBDProducts = () => {
  return (
    <Container className="my-5">
      <h1>CBD Products</h1>
      <p>Information about our CBD product offerings.</p>
    </Container>
  );
};

export default CBDProducts;
