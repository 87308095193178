import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Updated import to useNavigate

const Welcome = () => {
  const navigate = useNavigate();  // Initialize useNavigate hook

  // Handle button clicks
  const handleVisitPharmacyClick = () => {
    // Redirect to a different page or display a message
    alert("You have selected to visit the pharmacy. We will process your Rx accordingly.");
  };

  const handleNextClick = () => {
    // Navigate to the next page (e.g., insurance information page)
    navigate('/insurance/erx-ins-card');
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <div className="text-center">
            <h1 className="mb-4">Welcome to Shoreham Drugs</h1>
            <p>
              We have received your prescription. Please press the "Next" button to provide your insurance information and proceed electronically with your Rx. Alternatively, you can press "Visit Pharmacy" if you prefer to visit the pharmacy for further processing.
            </p>

            <div className="mt-4">
              <Button 
                variant="primary" 
                size="lg" 
                className="me-3" 
                onClick={handleVisitPharmacyClick}
              >
                Visit Pharmacy
              </Button>
              <Button 
                variant="success" 
                size="lg" 
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;
