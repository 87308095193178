// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

// Import pages
import Home from './pages/Home';
import FreeHomeDelivery from './pages/FreeHomeDelivery';
import CBDProducts from './pages/CBDProducts';
import Refills from './pages/Refills';
import Transfer from './pages/Transfer';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Halloween from './pages/Halloween';
import Thanksgiving from './pages/Thanksgiving';
import InsuranceWelcome from './pages/Welcome';
import InsuranceCard from './pages/eRxInsCard';
import './App.css';

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />

        <main className="flex-fill">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services/free-home-delivery" element={<FreeHomeDelivery />} />
            <Route path="/services/cbd-products" element={<CBDProducts />} />
            <Route path="/refills" element={<Refills />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/halloween" element={<Halloween />} />
            <Route path="/thanksgiving" element={<Thanksgiving />} />
            <Route path="/insurance/welcome" element={<InsuranceWelcome />} />
            <Route path="/insurance/erx-ins-card" element={<InsuranceCard />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
