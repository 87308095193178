// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';
import '../css/Footer.css'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

function Footer() {
  const position = [24.3513878, 54.4959206]; // Default position, replace with actual lat/lon later

//const Footer = () => {
  return (
    

    
    // <footer style={{ backgroundColor: 'rgb(0, 51, 102)', padding: '20px 0', marginTop: 'auto' }}>
    <footer >
      <Container className='footer' style={{width: 'inherit'}} >
        <div className='location'>
            <div className='text'>
                <h4 className='h4'>Shoreham Drugs</h4>
                <h3 className='h3'>Your Trusted Local Drug Store</h3>

                <div className="information">
                    <div>99-1 Route 25A</div>
                    <div><span>Shoreham</span>, <span>NY</span> <span>11786</span></div>
                    <div>United States</div>
                    <br></br><br></br>
                    {/* <div style={{display:'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}> */}
                    <div >
                    <div>Phone:&nbsp;&nbsp;&nbsp;<a style={{textDecoration:'none'}} href="tel:+16318210707">(631) 821-0707</a></div>
                    <div className="contact-info">Fax:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style={{textDecoration:'none'}}>(631) 821-5963</a></div>
                    </div>
                </div>


                <div className="office_hours">
                    <div class="row"><div class="day">Monday</div><div class="time">9:00 am - 7:00 pm</div></div>
                    <div class="row"><div class="day">Tuesday</div><div class="time">9:00 am - 7:00 pm</div></div>
                    <div class="row"><div class="day">Wednesday</div><div class="time">9:00 am - 7:00 pm</div></div>
                    <div class="row"><div class="day">Thursday</div><div class="time">9:00 am - 7:00 pm</div></div>
                    <div class="row"><div class="day">Friday</div><div class="time">9:00 am - 7:00 pm</div></div>
                    <div class="row"><div class="day">Saturday</div><div class="time">9:00 am - 4:00 pm</div></div>
                    <div class="row"><div class="day">Sunday</div><div class="time">Closed</div></div>
                </div>
		

            </div>

            <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d845.6610222142327!2d-72.89561745966455!3d40.9465824780083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85d5501a371b3%3A0xe9872bc7f9f9e0de!2sShoreham%20Drug!5e0!3m2!1sen!2s!4v1727908364358!5m2!1sen!2s" width="70%" height="450px" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      {/* <div id="map" style={{display:'flex', width: '100px', height:'100px' }} className="leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
                          <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: "100%", width: "100%" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={position}>
                                <Popup>
                                    Hurain Contracting Office
                                </Popup>
                            </Marker>
                          </MapContainer>
                      </div> */}
            </div>
        </div>
        <div className='footer-strip'>
          <div>&copy; {new Date().getFullYear()} Shoreham Drugs. All rights reserved.</div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a className='link' href='./terms-and-conditions'>Terms and Conditions</a>
            <span style={{ margin: '0 5px' }}>&nbsp;|&nbsp;</span>
            <a className='link' href='./privacy-policy'>Privacy Policy</a>
          </div>
        </div>
        
        {/* <div className="row text-center">
          &copy; {new Date().getFullYear()} Shoreham Drugs. All rights reserved.
        </div> */}
      </Container>
    </footer>
  );
}

export default Footer;
