// src/pages/Refills.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import '../css/Refills.css'; // Import the CSS file
import CryptoJS from 'crypto-js';

const Refills = () => {
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [rxNo, setRxNo] = useState('');
  const [pharmacyNo, setPharmacyNo] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const SECRET_KEY = 'G7#tA9s&jD2@vX!rQ4$yHn8zWmP3*eZf'; 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error/success message
    setErrorMessage('');
    setSuccessMessage('');

    // Check for empty fields
    if (!lastName || !dateOfBirth || !rxNo) {
      setErrorMessage('All fields are mandatory.');
      return;
    }
    
    const currentDate = new Date().toISOString();
    const nonceValue = Math.random().toString(36).substring(2, 15);  // Unique value to prevent replay
    //const key  = CryptoJS.AES.encrypt(currentDate, SECRET_KEY).toString();
    //const nonce  = CryptoJS.AES.encrypt(nonceValue, SECRET_KEY).toString();

    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY); // Ensure this is the same key used on the server

    const encryptedKey = CryptoJS.AES.encrypt(currentDate, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    // Combine IV and ciphertext
    const combinedKey = iv.concat(encryptedKey.ciphertext);

    const keyCipher = CryptoJS.enc.Base64.stringify(combinedKey);
    // Call the web service with fetch
    try {
      const response = await fetch('https://www.shorehamdrugs.com/web-api/pharmacy/api/submitrefill?key=' + encodeURIComponent(keyCipher) + '&nonce=' + encodeURIComponent(nonceValue), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lastName, dateOfBirth, rxNo, pharmacyNo }),
      });

      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      setSuccessMessage(data);
      console.log(data); // Handle the response as needed
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Failed to submit the refill order. Please try again later.');
    }
  };

  return (
    <Container className="my-5">
      {/* Wrapper for image and text */}
      {/* <div className="image-wrapper mb-4" style={{ position: 'relative'}}> */}
      <div className="image-wrapper">
        <img 
          src="img_refills.jpg" 
          alt="Pharmacy Refills" 
          className="img-fluid" 
        />
        {/* <h1 style={{ position: 'absolute', bottom: '40px', left: '17%', alignContent:'end', transform: 'translateX(-50%)', fontSize:'60px', color: 'white' }}></h1> */}
        <h1 className='image-wrapper h1'>
          REFILL REQUEST(S)
        </h1>
      </div>
      {/* Error Message Alert */}
      

      <div className="card">
      {errorMessage && (
        <Alert style={{ display: 'flex', color: 'maroon', margin: '0px', position: 'relative' }} variant="danger">
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert style={{ display: 'flex', color: 'darkgreen', margin: '0px', position: 'relative' }} variant="success">
          {successMessage}
        </Alert>
      )}
      <Form onSubmit={handleSubmit} className="mt-4 centered-form">
        <Form.Group controlId="formLastName" className="form-group">
          <Form.Label>Last Name: </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDateOfBirth" className="form-group">
          <Form.Label>Date of Birth: </Form.Label>
          <Form.Control
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formRxNo" className="form-group">
          <Form.Label>Rx No: </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Rx Number"
            value={rxNo}
            onChange={(e) => setRxNo(e.target.value)}
            required
          />
        </Form.Group>

        <div className="button-container">
          <Button id="bttnView" variant="primary" type="button" style={{ visibility: 'collapse' }}>
            View Refill Request(s)
          </Button>
          <Button id="bttnSubmit" variant="primary" type="submit">
            Submit Refill Order
          </Button>
        </div>
      </Form>
    </div>

    </Container>
  );
};

export default Refills;
